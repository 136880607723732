.inputs span {
  color: #EB1D24;
  text-align: left;
  line-height: 15px;
  margin-bottom: 8px;
}

.inputstyle {
  height: 40px;
  width: 100%;
  margin-bottom: 8px;
  border: 1px solid #0A0404;
  border-radius: 4px;
  padding: 12px;
}
.inputstyle::-moz-placeholder {
  color: #627884;
  font-size: 16px;
  opacity: 1; /* Firefox */
}
.inputstyle:-ms-input-placeholder {
  color: #627884;
  font-size: 16px;
  opacity: 1; /* Firefox */
}
.inputstyle::placeholder {
  color: #627884;
  font-size: 16px;
  opacity: 1; /* Firefox */
}
.inputstyle:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #627884;
  font-size: 16px;
}
.inputstyle::-ms-input-placeholder { /* Microsoft Edge */
  color: #627884;
  font-size: 16px;
}/*# sourceMappingURL=input.module.css.map */